import React from 'react';
import './Technologies.scss';
/* import figma from '/public/icons/figma.png'
import notion from '/icons/notion.png'
import postgresql from '/icons/postgresql.png'
import python from '/icons/python.png'
import reactImg from '/icons/react.png'
import redis from '/icons/redis.png' */

const Technologies = ({language}) => {
  const img1 = <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
  <script xmlns="" id="exodus-extension" data-extension-id="aholpfdialjgjfhomihkjbmgjidlcdno" data-prioritize-exodus="true"/><path d="M10.7886 40.3333C5.38251 40.3333 1 44.8851 1 50.5C1 56.1149 5.38251 60.6667 10.7886 60.6667M10.7886 40.3333H21.9756M10.7886 40.3333C5.38251 40.3333 1 35.7816 1 30.1667C1 24.5518 5.38251 20 10.7886 20L21.9756 20M10.7886 60.6667H21.9756M10.7886 60.6667C5.38251 60.6667 1 65.2184 1 70.8333C1 76.4482 5.38251 81 10.7886 81H12.187C17.5931 81 21.9756 76.4482 21.9756 70.8333V60.6667M21.9756 40.3333V60.6667M21.9756 40.3333H32.9878C39.0697 40.3333 44 35.7816 44 30.1667C44 24.5518 39.0697 20 32.9878 20L21.9756 20M21.9756 40.3333L21.9756 20M31.939 40.3333C37.4417 40.3333 41.9024 44.8851 41.9024 50.5C41.9024 56.1149 37.4417 60.6667 31.939 60.6667C26.4364 60.6667 21.9756 56.1149 21.9756 50.5C21.9756 44.8851 26.4364 40.3333 31.939 40.3333Z" stroke="#1390E5" stroke-width="2"/>
  </svg>
  
  const img2 = <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
  <script xmlns="" id="exodus-extension" data-extension-id="aholpfdialjgjfhomihkjbmgjidlcdno" data-prioritize-exodus="true"/><path d="M22.9704 19L31.1682 19V64.3603C26.4049 65.2358 22.8088 65.5803 18.9397 65.5804C12.843 65.574 8.38148 64.1937 5.45369 61.6555C2.55147 59.1395 1 55.3485 1 50.1594C1 45.1876 2.63948 41.1743 5.47019 38.4041C8.30299 35.6319 12.4192 34.0165 17.5343 34.0165C18.6727 34.0165 19.5904 34.0808 20.5339 34.2488C21.7349 34.4627 22.9704 33.5816 22.9704 32.25V19ZM23.3278 42.7689C23.3278 41.9222 22.7823 41.1059 21.8864 40.8726C20.8812 40.6108 19.9616 40.5206 18.8892 40.5206C16.1007 40.5206 13.7755 41.3851 12.155 43.1227C10.5439 44.8503 9.752 47.3097 9.752 50.264C9.752 53.1404 10.509 55.5257 12.1066 57.1943C13.7108 58.8699 16.022 59.6766 18.8236 59.6766C19.7489 59.6766 20.5443 59.6347 21.5735 59.5177C22.5901 59.4022 23.3278 58.5364 23.3278 57.5415V42.7689Z" stroke="#1390E5" stroke-width="2"/>
  <path d="M46.1209 73.0234L46.1208 73.0234L46.115 73.0343C45.3078 74.5683 44.5259 75.7452 43.4507 76.7985C42.3734 77.8539 40.9581 78.8276 38.8362 79.8921L31.4025 76.4653C31.4016 76.4648 31.4008 76.4645 31.4002 76.4641C31.3983 76.4594 31.3951 76.4489 31.3949 76.4322C31.3946 76.4087 31.4006 76.3868 31.409 76.3714C31.4156 76.3592 31.4241 76.3495 31.4402 76.3404L30.9479 75.4699L31.4403 76.3404C35.1408 74.2473 37.5563 71.5993 38.8908 68.9545C39.721 67.3449 40.0968 65.8932 40.2645 63.723C40.4048 61.9074 40.4023 59.5345 40.3987 56.0955C40.3982 55.5217 40.3975 54.9183 40.3975 54.2828V35L49 35V57.831C49 65.9323 47.8615 69.8125 46.1209 73.0234Z" stroke="#1390E5" stroke-width="2"/>
  <path d="M40 19L49 19V27H40L40 19Z" stroke="#1390E5" stroke-width="2"/>
  </svg>


  const img3 = <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
  <script xmlns="" id="exodus-extension" data-extension-id="aholpfdialjgjfhomihkjbmgjidlcdno" data-prioritize-exodus="true"/><path d="M50.5002 78.5788C44.4019 82.0996 32.071 72.1586 22.9583 56.3749C13.8456 40.5913 11.4019 24.9419 17.5002 21.4211C23.5984 17.9003 35.9293 27.8413 45.042 43.6249C54.1547 59.4086 56.5984 75.058 50.5002 78.5788Z" stroke="#1390E5" stroke-width="2"/>
  <path d="M50.5002 21.4211C56.5984 24.9419 54.1547 40.5913 45.042 56.3749C35.9293 72.1586 23.5984 82.0996 17.5002 78.5788C11.402 75.058 13.8457 59.4086 22.9584 43.6249C32.0711 27.8413 44.402 17.9003 50.5002 21.4211Z" stroke="#1390E5" stroke-width="2"/>
  <path d="M67 49.9999C67 57.1796 52.2254 62.9999 34 62.9999C15.7746 62.9999 1 57.1796 1 49.9999C1 42.8202 15.7746 36.9999 34 36.9999C52.2254 36.9999 67 42.8202 67 49.9999Z" stroke="#1390E5" stroke-width="2"/>
  <path d="M39 49.9999C39 52.7614 36.7614 54.9999 34 54.9999C31.2386 54.9999 29 52.7614 29 49.9999C29 47.2385 31.2386 44.9999 34 44.9999C36.7614 44.9999 39 47.2385 39 49.9999Z" stroke="#1390E5" stroke-width="2"/>
  </svg>

  const img4 = <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
  <script xmlns="" id="exodus-extension" data-extension-id="aholpfdialjgjfhomihkjbmgjidlcdno" data-prioritize-exodus="true"/><rect x="2" y="22" width="56" height="56" stroke="#1390E5" stroke-width="2"/>
  <path d="M39.2108 39.5084L42.9044 34.8001C42.9044 34.8001 39.5641 29.4889 30.1198 30.0401C20.6756 30.5913 17.9849 36.5592 18.1326 41.8884C18.259 46.4484 23.3259 51.0256 26.948 51.9775C30.7675 53.3009 36.0589 54.9266 36.2066 57.7723C36.2066 59.8336 35.4679 62.0642 29.7059 61.7562C24.4148 61.2063 21.3337 58.1345 21.3337 58.1345L17 63.7223C17 63.7223 21.9739 68.9997 30.1198 68.9997C34.7141 69.0316 43.7416 66.568 43.9878 59.0628C44.2341 51.5576 40.7213 48.9468 35.4679 46.8553C30.2145 44.7639 24.3449 42.2515 26.6525 38.9432C30.1198 33.9723 39.2108 39.5084 39.2108 39.5084Z" stroke="#1390E5" stroke-width="2"/>
  </svg>

  const img5 = <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
  <script xmlns="" id="exodus-extension" data-extension-id="aholpfdialjgjfhomihkjbmgjidlcdno" data-prioritize-exodus="true"/><path d="M63.9999 23L33 77L1.99994 23H13.5142L33 56.9429L52.4857 23H63.9999Z" stroke="#1390E5" stroke-width="2"/>
  <path d="M36.9857 23L33 29.9429L29.0142 23H17.9999L33 49.129L47.9999 23H36.9857Z" stroke="#1390E5" stroke-width="2"/>
  </svg>
  const content = {
    en: [
        `Technologies for Telegram App Development`,
        `We design prototypes in Figma and show interactive presentations.`,
        `The app's backend is built on Python. We use FastAPI - the fastest framework!`,
        `We create simple and fast UI components using React.`,
        `For data storage we use the most reliable database - PostgreSQL`,
        `For tasks that require speed - Redis`,
        `You can track project development in real time with reports in Notion from our team`

    ],
    ru: [
      `Технологии для разработки приложений для Telegram`,
      `Мы разрабатываем прототипы в Figma и показываем интерактивные презентации.`,
      `Бэкенд приложения построен на Python. Мы используем FastAPI - самый быстрый фреймворк!`,
      `Мы создаем простые и быстрые UI-компоненты с использованием React.`,
      `Для хранения данных мы используем самую надежную базу данных - PostgreSQL.`,
      `Для задач, требующих скорости - Redis.`,
      `Вы можете отслеживать разработку проект в Notion нашей команды.`


    ]
  }
  return (
    <section className="technologies">
      <h2>{content[language][0]}</h2>
      <div className='divider'>
        
      </div>
      <div className="technology-grid">
        <div className="technology-item">
            
        <img src='/icons/figma.png'></img>
          <p>{content[language][1]}</p>
        </div>
        <div className="technology-item">
          <img src='/icons/python.png'></img>
          <p>{content[language][2]}</p>
        </div>
        <div className="technology-item">
          <img src='/icons/react.png'></img>
          <p>{content[language][3]}</p>
        </div>
        <div className="technology-item">
        <img src='/icons/postgresql.png'></img>
          <p>{content[language][4]}</p>
        </div>
        <div className="technology-item">
        <img src='/icons/redis.png'></img>
          <p>{content[language][5]}</p>
        </div>

        <div className="technology-item">
        <img src='/icons/notion.png'></img>
          <p>{content[language][6]}</p>
        </div>
      </div>
    </section>
  );
};

export default Technologies;
