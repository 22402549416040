import logo from './logo.svg';
import './App.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import Router from './Router';
import { LanguageProvider } from './lang';
function App() {
  return (
    <Provider store={store}>
      <LanguageProvider> {/* Добавляем LanguageProvider вокруг приложения */}
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </LanguageProvider>
    </Provider>
  );
}

export default App;
