import React, { useState } from 'react';
import './Team.scss';

const Team = ({language}) => {
	// Данные о членах команды
	const teamMembers = [
		{
		  id: 1,
		  name: 'Andre',
		  role: 'CEO / founder',
		  image: '/avatar/andre.png',
		  description: {
			en: '2.5 years of experience in a Large GameFi Web3 project',
			ru: '2,5 года опыта работы в крупном Web3 GameFi проекте',
		  },
		},
		{
		  id: 2,
		  name: 'Nik',
		  role: 'Co-founder',
		  image: '/avatar/nik.png',
		  description: {
			en: '3 years of experience in a production agency',
			ru: '3 года опыта работы в продакшн-агентстве',
		  },
		},
		{
		  id: 3,
		  name: 'Yurii',
		  role: 'Backend Developer',
		  image: '/avatar/Yurii.png',
		  description: {
			en: `More than 5 years of development experience.
			Worked with LinkedIn team leader.
			Holds a verified certificate from Amazon “AWS Certified Developer Associate”`,
			ru: `Более 5 лет опыта разработки.
			Работал с тимлидером LinkedIn.
			Имеет проверенный сертификат Amazon «AWS Certified Developer Associate»`,
		  },
		},
		{
		  id: 4,
		  name: 'Alex',
		  role: 'Frontend Developer',
		  image: '/avatar/alex.png',
		  description: {
			en: '3+ years of experience',
			ru: 'Более 3 лет опыта',
		  },
		},
		{
		  id: 5,
		  name: 'Ksu',
		  role: 'UI/UX designer',
		  image: '/avatar/ksu.png',
		  description: {
			en: '2.5 years of experience in GameFi and Web3 projects',
			ru: '2,5 года опыта работы в проектах GameFi и Web3',
		  },
		},
	  ];

	// Состояние для отслеживания выбранного члена команды
	const [selectedMember, setSelectedMember] = useState(null);

	return (
		<div className="team-section">
			<h1>{language != 'ru' ? "Meet Our Executive Team" : 'Познакомьтесь с нашей командой'}</h1>
			<div className="team-grid">
				{teamMembers.map((member) => (
					<div
						key={member.id}
						className="team-member"
						onClick={() => setSelectedMember(member)}
					>
						<img src={member.image} alt={member.name} />
						<h3>{member.name}</h3>
						<p>{member.role}</p>
					</div>
				))}
			</div>
			{selectedMember && (
				<div
					className="member-details"
					style={{
						backgroundImage: `url(${selectedMember.image})`,
						backgroundSize: 'cover',
						backgroundPosition: '0 30%',
					}}
				>
					<div className="member-details-content">
						<h2>{selectedMember.name}</h2>
						<h4>{selectedMember.role}</h4>
						<p>{selectedMember.description[language]}</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default Team;
