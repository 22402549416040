import React, { useEffect, useRef } from 'react';
import styles from './ChatBoxSecond.module.scss';

const ChatBoxSecond = ({language}) => {
	const userMessageRefs = useRef([]);
	const botMessageRefs = useRef([]);

	useEffect(() => {
		const observerOptions = {
			threshold: 0.1, // Сообщение считается видимым, когда его 50% появляется в зоне видимости
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add(styles.visible);
				}
			});
		}, observerOptions);

		userMessageRefs.current.forEach((ref) => ref && observer.observe(ref));
		botMessageRefs.current.forEach((ref) => ref && observer.observe(ref));

		// Отсоединение наблюдателя при размонтировании компонента
		return () => {
			userMessageRefs.current.forEach((ref) => ref && observer.unobserve(ref));
			botMessageRefs.current.forEach((ref) => ref && observer.unobserve(ref));
		};
	}, []);

	const content = {
		en:[
			'What is AKZY?',
			`AKZY is a solution for businesses of all caliber that can increase
					your profits like never before.`,
			`How do you work?`,
			`We do applications in just three steps with these technologies`,
			`Where can I reach you`,
			`You can leave a message here or chat with our support right now!`
		],
		ru:[
			'Что такое AKZY?',
			`AKZY Studio - студия разработки Mini Apps полного цикла. Мы сделаем для вас виральную игру или любое приложение для вашего бизнеса.`,
			`Как вы работаете?`,
			`С помощью этих технологий мы создаем приложения всего за три шага.`,
			`Где я могу с вами связаться?`,
			`Вы можете связаться с нами в Telegram или по E-mail`

		]
	}

	return (
		<div className={styles.chatContainer} id="about">
			{/* Сообщение пользователя */}
			<div
				className={styles.userMessage}
				ref={(el) => userMessageRefs.current.push(el)}
			>
				<p className={styles.messageText}>{content[language][0]}</p>
			</div>

			{/* Ответ бота */}
			<div
				className={styles.botMessage}
				ref={(el) => botMessageRefs.current.push(el)}
			>
				<p className={styles.messageText}>
				{content[language][1]}
				</p>
			</div>

		

			{/* Сообщение пользователя */}
			<div
				className={styles.userMessage}
				ref={(el) => userMessageRefs.current.push(el)}
			>
				<p className={styles.messageText}>{content[language][4]}</p>
			</div>

			{/* Ответ бота */}
			<div
				className={styles.botMessage}
				ref={(el) => botMessageRefs.current.push(el)}
			>
				<p className={styles.messageText}>
				{content[language][5]}
				</p>
			</div>
		</div>
	);
};

export default ChatBoxSecond;
