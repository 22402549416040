import React, { useState } from "react";
// import BalanceImage from "../assets/balance.png";
// import ScreenImage from "../assets/screen.png";
// import IconsImage from "../assets/icons.png";
// import PurchageImage from "../assets/purchase.png";
// import MultiTap from "../assets/multitap.png";

import CheckBoxIcon from "../../assets/checkbox.svg";
import PhonePanel from "../../assets/phone.png";
// import SilverStarIcon from "../assets/silverstar.svg";
// import PieIcon from "../assets/pie.svg";
// import GoldStarIcon from "../assets/goldstar.svg";

import PurchaseModal from "./PurchaseModal";
import { useLocation, useNavigate } from "react-router-dom";

const TapBotTemplate = ({ language }) => {
  const [isOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const description = {
    en: "Ready-made TapBot with the possibility of customization and free installation on your servers",
    ru: "Готовая Тапалка с возможностью кастомизаций и бесплатной установкой на ваши сервера",
  };

  const options = [
    {
      en: "Main screen",
      ru: "Основной экран",
    },
    {
      en: "Boosts",
      ru: "Бусты",
    },
    {
      en: "Leaderboard",
      ru: "Лидерборд",
    },
    {
      en: "Referral system",
      ru: "Реферальная система",
    },
    {
      en: "Squads",
      ru: "Сквады",
    },
    {
      en: "Skin shop with payment in Ton or Telegram stars",
      ru: "Магазин скинов c оплатой в Ton или Telegram stars",
    },
    {
      en: "Connecting advertising networks",
      ru: "Подключение рекламных сетей",
    },
    {
      en: "Admin panel",
      ru: "Админ панель",
    },
    {
      en: "Possibility of updates",
      ru: "Возможность обновлений",
    },
  ];

  return (
    <>
      <div className="flex flex-col lg:flex-row bg-[#222222] rounded-2xl lg:max-w-4xl mx-auto justify-between text-left overflow-hidden lg:transform lg:transition-transform lg:duration-300 lg:hover:scale-110">
        {/* Left Side - Image */}
        <img
          src={PhonePanel}
          alt="PhonePanel"
          className="w-full lg:w-2/5 h-auto justify-stretch"
        />

        {/* Right Side - Details */}
        <div className="p-4 sm:p-6 lg:p-8 w-full lg:w-3/5 flex flex-col justify-between items-start">
          <div>
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-2">
              Tap2Earn Template
            </h2>
            <p className="text-gray-400 mb-6 text-sm sm:text-base">
              {description[language]}
            </p>

            <ul className="space-y-2 text-white columns-1 sm:columns-2">
              {options.map((option, index) => {
                return (
                  <li className="flex items-center">
                    <img
                      src={CheckBoxIcon}
                      className="mr-2 w-[16px] h-[16px] mx-0"
                      alt={`Option-${index}`}
                    />
                    {option[language]}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="mt-6 flex flex-col sm:flex-row items-center justify-between w-full gap-4">
            {/* Price Text */}
            <div className="flex items-baseline space-x-2">
              <span className="text-white text-2xl sm:text-3xl font-semibold">
                $399
              </span>
              <span className="text-gray-400 text-lg sm:text-xl">
                / no servers
              </span>
            </div>

            {/* Button */}

            <button
              onClick={() => {
                window.location.href = "https://t.me/AKZYTestClickerBot";
              }}
              className="bg-blue-400 text-black font-bold py-2 sm:py-3 px-8 sm:px-8 rounded-lg hover:bg-lime-500 transition duration-300 w-full sm:w-auto"
            >
              TRY IT
            </button>
            <button
              onClick={openModal}
              className="bg-lime-400 text-black font-bold py-2 sm:py-3 px-8 sm:px-8 rounded-lg hover:bg-lime-500 transition duration-300 w-full sm:w-auto"
            >
              GET IT
            </button>
          </div>
        </div>
      </div>
      <PurchaseModal
        isOpen={isOpen}
        onClose={closeModal}
        description={description[language]}
      ></PurchaseModal>
    </>
  );
};

export default TapBotTemplate;
