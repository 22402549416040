import React, { useEffect, useRef, useState } from 'react';
import './WhoAreWe.scss';
import { useScroll, useTransform, motion } from 'framer-motion';

const WhoAreWe = ({language}) => {

	const isMobile = window.innerWidth <= 768;

	const content = {
		en:{
			greentext: `AKZY is the easiest way how to make the best Web3 application and
				promote it`,
			pinktext: `Our expert development and PR team provides end-to-end support, from
				initial concept to project launch and digital marketing.`,
			info: 'Get more information'
		},
		ru:{
			greentext: `AKZY - это самый простой способ, как сделать лучшее Web3-приложение и
				продвигать его`,
			pinktext: `Наша команда экспертов по разработке и PR обеспечивает комплексную поддержку, от
				 первоначальной концепции до запуска проекта и цифрового маркетинга.`,
			info: 'Получить больше информации'

		},
	}

	const containerRef = useRef(null);

	const { scrollYProgress } = useScroll({
		target: containerRef,
		offset: ['40% end', '80% 70%'],
	});

	const { scrollYProgress: scrollY } = useScroll({
		target: containerRef,
		offset: ['start end', '20% 80%'],
	});

	const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
	const firstOpacity = useTransform(scrollY, [0, 1], [0, 1]);
	const firstPositionY = useTransform(scrollY, (v) => `${50 - v * 50}px`);
	const positionY = useTransform(scrollYProgress, (v) => `${isMobile? v * 40 : v * 80}%`);

	return (
		<div className="container-for-whoarewe" ref={containerRef}>
			<motion.div
				className={`whoarewe green `}
				style={{ opacity: firstOpacity, y: firstPositionY }}
			>
				{content[language].greentext}
			</motion.div>
			<motion.div
				className={`whoarewe purple `}
				style={{ opacity, y: positionY }}
			>
					{content[language].pinktext}
				<button className="blackbtn purpletext">
					<a href="#contactus" className="no-style-link">
					{content[language].info}
					</a>
				</button>
			</motion.div>
		</div>
	);
};

export default WhoAreWe;
