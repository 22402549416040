import React from 'react';
import './SocialFollow.scss';

const SocialFollow = ({language}) => {
	return (
		<section className="social-follow-section" id="contactus">
			<div className="social-follow-card">
				<h3>{language != 'ru' ?'Contact us' : 'Связаться с нами'}</h3>
				<a
					href="mailto:contact@akzystudio.com"
					target="_blank"
					rel="noopener noreferrer"
					className="social-link"
				>
					e-mail
				</a>
				<a href="https://t.me/akzy_studio" className="social-link">
					Telegram
				</a>
			</div>
		</section>
	);
};

export default SocialFollow;
