import React from 'react';
import './SubHeader.scss';

const SubHeader = ({language}) => {
	const content = {
		en:{
			h1:`The quickest way to develop and launch a custom Telegram Mini App for
					your business`,
			contact : 'Contact us',
			readyProject: 'Buy a ready-made project'
		},
		ru:{
			h1:`Самый простой способ разработать и запустить Telegram Mini App`,
			contact : 'Свяжитесь с нами',
			readyProject: 'Купить готовый проект'
		}
	}
	return (
		<div className="subheader">
			<div className="content">
				<h1>
					{content[language].h1}
				</h1>
				<div className='button-wrapper'>
				<button>
					<a className="no-style-link" href="#contactus">
					{content[language].contact}
					</a>
				</button>
					<a className="pink-link" href="#ready">
					{content[language].readyProject}
					</a>
				</div>
			</div>
			<div className="banner"></div>
		</div>
	);
};

export default SubHeader;
