import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import MainPage from './pages/MainPage/MainPage';

export default function Router() {
    const navigate = useNavigate();
    useEffect(() => {
        // Определяем язык браузера
        const userLanguage = navigator.language || navigator.userLanguage;

        // Проверяем, если пользователь на главной странице или несуществующем маршруте
        if (window.location.pathname === '/') {
            // Проверяем, является ли язык русским
            if (userLanguage.startsWith('ru')) {
                navigate('/ru', { replace: true });  // Перенаправляем на русскую версию
            } else {
                navigate('/en', { replace: true });  // Перенаправляем на английскую версию
            }
        }
    }, [navigate]);
    return (
        <Routes>
            
            <Route path="/en/*" element={<MainPage language="en" />} />
            <Route path="/ru/*" element={<MainPage language="ru" />} />
        </Routes>
    );
}
