import React from 'react';
import './HeroSection.scss';

const HeroSection = () => {
  return (
    <section className="hero-section">
      {/* 3D элементы */}
      <img src="/parallax-icons/A_icon_Final.png" alt="3D Element 1" className="floating-element element1" />
      <img src="/parallax-icons/NFT_icon_Final.png" alt="3D Element 2" className="floating-element element2" />
      <img src="/parallax-icons/POCKET_icon_Final.png" alt="3D Element 3" className="floating-element element3" />
      <img src="/parallax-icons/TG_icon_Final.png" alt="3D Element 4" className="floating-element element4" />
      <img src="/parallax-icons/TON_icon_Final.png" alt="3D Element 5" className="floating-element element5" />
      {/* Текст */}
      <div className="hero-text">
        <h1>
          Para allows you to focus on what you do best,
          while your fans support your work.
        </h1>
        <button className="cta-button">Sign In</button>
      </div>
    </section>
  );
};

export default HeroSection;
