import React from 'react';
import './Marquee.scss';

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee-content">
        <p className="marquee-text">
          We believe in Telegram Mini Apps. AKZY Web Studio. 2024 &nbsp; • &nbsp; We believe in Telegram Mini Apps. AKZY Web Studio. 2024 &nbsp; • &nbsp; 
          We believe in Telegram Mini Apps. AKZY Web Studio. 2024 &nbsp; • &nbsp; We believe in Telegram Mini Apps. AKZY Web Studio. 2024 &nbsp; • &nbsp; 
          {/* Повторяйте текст столько раз, сколько нужно для заполнения пространства */}
        </p>
      </div>
    </div>
  );
};

export default Marquee;
