import React from 'react';
import './CaseStudies.scss';

const CaseStudies = ({language}) => {
	const caseStudies = [
		

		{
			id: 1,
			title: 'MOON BOYS',
			description: {
			  en: 'Tap2earn game that was created based on our template with a few custom elements. This game includes classic clicker game mechanics + monetization tools : AdsGram ad network integration, Selling skins for Telegram starts. The project was implemented in the shortest possible time and from the first day began to bring the customer income !',
			  ru: 'Tap2earn игра которая была создана на основе нашего шаблона с несколькими кастомными элементами. Эта игра включает классические механики кликкер игры + инструменты монетизации : Интеграция рекламной сети AdsGram , Продажа скинов за Telegram starts. Проект был реализован в кратчайшие сроки и уже с первого дня начал приносить заказчику доход !'
			},
			industry: 'Clicker game',
			image: '/avatar/moonboys.png',
			link: 'https://t.me/TapMBoys_Bot',
		  },
		{
		  id: 2,
		  title: 'TIME TO MONEY',
		  description: {
			en: 'A game in which rewards for time spent in it with social mechanics and mini events that was developed from scratch. In the game there are different skins that affect the characteristics. We also made mini events that fall at random times and connected Web3 tools using TON wallets.',
			ru: 'Игра, в которой награды даются за проведенное в ней время, с социальными механиками и мини-событиями, была разработана с нуля. В игре есть различные скины, которые влияют на характеристики. Мы также добавили мини-события, которые происходят в случайное время, и подключили Web3-инструменты с использованием TON-кошельков.'
		  },
		  industry: 'W2E GAME',
		  image: '/avatar/ttm.png',
		  link: 'https://t.me/timetoomoney_bot',
		},
		{
			id: 3,
			title: 'TAPPY BIRD',
			description: {
			  en: 'Clicker game with collection mechanics was developed by us from scratch. We realized a classic clicker supplemented with our ideas. More than 10,000 unique birds were created. Thought out economy and additional mechanics such as wheel of fortune and daily entry and web3 features with any Ton wallet. We selected the optimal server part and at the moment the game has more than 50,000 monthly players.',
			  ru: 'Кликер-игра с механикой коллекционирования была разработана нами с нуля. Мы реализовали классический кликер, дополненный нашими идеями. Было создано более 10 000 уникальных птиц. Продумана экономика и дополнительные механики, такие как колесо удачи, ежедневный вход и Web3-функции с любым Ton-кошельком. Мы выбрали оптимальную серверную часть, и на данный момент в игре более 50 000 ежемесячных игроков.'
			},
			industry: 'Clicker game',
			image: '/avatar/tappy bird.png',
			link: 'https://t.me/tappybird_bot',
		  },
	  ];
	  
	  

	return (
		<div className="case-studies" id='roadmap'>
			<h1>Our Development Cases</h1>
			<div className="case-grid">
				{caseStudies.map((study) => (
					<div key={study.id} className="case-card">
						<img src={study.image} alt={study.title} />
						<div className="case-info">
							<h2>{study.title}</h2>
							<p>{study.description[language]}</p>
							<div className="cards">
								<span>{study.industry}</span>
								<a href={study.link}>TRY IT</a>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CaseStudies;
