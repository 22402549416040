import React from 'react';
import './DevelopmentSteps.scss';

const DevelopmentSteps = ({language}) => {
	const content = {
		en:[
			`Web apps are the modern alternative to chatbots, mobile apps and
						full-fledged websites in any area of business.`,
			`Pros of WebApps`,
			`Provides an additional channel of interaction with the audience.`,
			`Collects user data and opens up new advertising opportunities.`,
			`Costs less than a full-fledged site, but is not inferior in
							functionality.`,
			`Ask a question`,
			`Conditions of work`, 
			`We collect the main wishes of the customer, assess the scale of
							the project, tell you about the full cycle of our work, discuss
							the terms of work.`,
			`Analytics and game-design`,
			`We develop a detailed story map - the path of user interaction
							with the content and coordinate the details of the project.`,

			`Graphic design`,
			`Collect UI elements, develop a detailed prototype, present the
							mockup.`,
			`Development and debugging`,
			`Programming, code-reviewing and testing.`,
			`Release and support`,
			`We launch the application and provide service support for 1 year.`,
			`Telegram is a platform with 900 million active users. Creating your own Mini app is the key to reaching those 900 million people`

		],
		ru:[

			`Веб-приложения — современная альтернатива чат-ботам, мобильным приложениям и полноценным сайтам в любой сфере бизнеса.`,
`Преимущества веб-приложений`,
`Обеспечивают дополнительный канал взаимодействия с аудиторией.`,
`Собирают пользовательские данные и открывают новые рекламные возможности.`,
`Стоят дешевле полноценного мобильного приложения, но не уступают ему по функциональности, при этом не требуют выгрузки в сторы.
`,
`Задать вопрос`,
`Условия работы`,
`Мы собираем основные пожелания заказчика, оцениваем масштаб проекта, рассказываем о полном цикле нашей работы, обсуждаем условия выполнения.`,
`Аналитика и геймдизайн`,
`Мы разрабатываем подробный план — путь взаимодействия пользователя с контентом и согласовываем детали проекта.`,
`Графический дизайн`,
`Собираем элементы пользовательского интерфейса, разрабатываем детализированный прототип, представляем макет.`,
`Разработка и отладка`,
`Написание и тестирование кода, минимум 2 дебаг сессии`,
`Релиз и поддержка`,
`Мы самостоятельно запускаем приложение на лучших серверах и предоставляем сервисную поддержку на 1 год.`,
`Telegram - платформа с 900 миллионами активных юзеровю. Создание своего Mini app - ключ к охвату этих 900 милллионов человек`

		]
	}
	return (
		<section className="development-steps">
			<div className="development-steps__content">
				<div className="development-steps__intro">
					<h2>#akzydevelopment</h2>
					<p>
						{content[language][0]}  {' '}
					</p>
					<h2>{content[language][1]}</h2>
					<ul>
						<li>
						{content[language][2]}
						</li>
						<li>
						{content[language][3]}
						</li>
						<li>
						{content[language][4]}
						</li>
						<li>
						{content[language][16]}
						</li>
					</ul>
					<button>
						<a href="#contactus" className="no-style-link ">
						{content[language][5]}
						</a>
					</button>
				</div>
				<div className="development-steps__grid">
					<div className="development-steps__card step-1">
						<img src="/icons/research.png" alt="Предварительная оценка" />
						<h3>{content[language][6]}</h3>
						<p>
						{content[language][7]}
						</p>
					</div>
					<div className="development-steps__card step-2">
						<img src="/icons/game-design.png" alt="Аналитика" />
						<h3>{content[language][8]}</h3>
						<p>
						{content[language][9]}
						</p>
					</div>
					<div className="development-steps__card step-3">
						<img src="/icons/design.png" alt="Дизайн" />
						<h3>{content[language][10]}</h3>
						<p>
						{content[language][11]}
						</p>
					</div>
					<div className="development-steps__card step-4">
						<img src="/icons/development.png" alt="Разработка и отладка" />
						<h3>{content[language][12]}</h3>
						<p>{content[language][13]}</p>
					</div>
					<div className="development-steps__card step-5">
						<img src="/icons/release.png" alt="Разработка и отладка" />
						<h3>{content[language][14]}</h3>
						<p>
						{content[language][15]}
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DevelopmentSteps;
