import React, { useRef, useState, useEffect } from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';
import './YellowAndGreenBoxes.scss';

const YellowAndGreenBoxes = ({ language }) => {
	const containerRef = useRef();

	const isMobile = window.innerWidth <= 768;

	const { scrollYProgress } = useScroll({
		target: containerRef,
		offset: isMobile
			? ['start end', 'center center']
			: ['100vh end', '200vh 100vh'],
	});

	const position = useTransform(scrollYProgress, (v) => `${v * 104}%`);
	const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
	const positionY = useTransform(scrollYProgress, [0, 1], [150, 0]);
	const styleVarYellow = isMobile ? { y: positionY } : {};

	const styleVar = isMobile ? { opacity } : { x: position, opacity };

	function getRandomElement(array) {
		const randomIndex = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	}

	const [classname, setClassname] = useState('whyus yellow');
	const [innerhtml, setInnerhtml] = useState(
		language == 'en'
			? "Say goodbye to unreliable freelancers, choose AKZY's professional development services."
			: 'Попрощайтесь с ненадежными фрилансерами'
	);
	const darkGreenRef = useRef(null);
	const classesAndTexts = [
		[
			'whyus yellow green-bc',
			{ en: 'Bye-bye, boring WordPress', ru: 'Прощай, скучный WordPress' },
		],
		[
			'whyus yellow pink-bc',
			{ en: 'Bye-bye, boring Chatbots', ru: 'Пока-пока, скучные чатботы' },
		],
		[
			'whyus yellow light-blue-bc',
			{ en: 'Bye-bye, boring Web2', ru: 'Пока-пока, скучный Web2' },
		],
		[
			'whyus yellow',
			{
				en: "Say goodbye to unreliable freelancers, choose AKZY's professional development services.",
				ru: 'Попрощайтесь с ненадежными фрилансерами',
			},
		],
	];

	const handle = () => {
		const pair = getRandomElement(classesAndTexts);
		setClassname(pair[0]);
		setInnerhtml(pair[1][language]);
		console.log([pair[0], pair[1]]);
	};

	return (
		<div className="yellow-and-green-boxes-container" ref={containerRef}>
			<motion.div className={classname} onClick={handle} style={styleVarYellow}>
				<h2
					className="yellow-title"
					style={{
						whiteSpace: 'pre-line',
						overflowWrap: 'break-word',
						fontWeight: 'bold',
					}}
				>
					{innerhtml}
				</h2>
				<p className="yellow-text">
					{language == 'en'
						? `AKZY offers complete development solutions, eliminating the need for
					large, expensive teams.`
						: `Мы предлагаем разработку и продвижение под ключ, устраняя необходимость в дорогостоящем подборе сотрудников`}
				</p>
			</motion.div>
			<motion.div
				className="whyus darkgreen"
				ref={darkGreenRef}
				style={styleVar}
			>
				<h2 className="darkgreen-title">
					{language == 'en'
						? `Get started now by creating your game and start making a profit in the
					first few days. Easy!`
						: `Начните прямо сейчас, создайте свою игру и начните получать прибыль в
					первые несколько дней. Легко!`}
				</h2>
				<button className="blackbtn darkgreentext greenBtn">
					<a className="no-style-link " href="#contactus">
						{language == 'en' ? `Contact us` : `Свяжитесь с нами`}
					</a>
				</button>
			</motion.div>
		</div>
	);
};

export default YellowAndGreenBoxes;
