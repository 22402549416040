import React from "react";
import TapBotTemplate from "./TapBotTemplate";

const TemplatePack = ({ language }) => {
  return (
    <div
      className="flex-grow flex flex-col items-center justify-center space-y-8 py-8"
      // style={{ zIndex: "2000" }}
      id="ready"
    >
      {/* h1 Heading */}
      <h1 className="text-white text-[2.5rem] font-bold text-center">
        Launch-ready Projects
      </h1>

      {/* TemplateCard Component */}
      <TapBotTemplate language={language} />
    </div>
  );
};

export default TemplatePack;
