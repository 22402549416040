import React, { useRef } from 'react';
import './ParallaxComponent.scss';
import { useScroll, useTransform, motion } from 'framer-motion';

const icons = [
	'/parallax-icons/A_icon_Final.png',
	'/parallax-icons/NFT_icon_Final.png',
	'/parallax-icons/POCKET_icon_Final.png',
	'/parallax-icons/TG_icon_Final.png',
	'/parallax-icons/TON_icon_Final.png',
];

const ParallaxComponent = ({language}) => {
	const isMobile = window.innerWidth <= 768;
	const containerRef = useRef();
	const { scrollYProgress } = useScroll({
		target: containerRef,
		offset: ['start end', 'end start'],
	});
	const positionY = useTransform(scrollYProgress, (v) => `-${v * 400}px`);
	const rotate = useTransform(scrollYProgress, [0, 1], [0, 40]);
	return (
		<div className="parallax-component" ref={containerRef}>
			<div className="content">
				<h2>
					{language != 'ru' ? `AKZY manages the technical development of your project, allowing you
					to maximize business outcomes and increase revenue effortlessly.` : `AKZY управляет техническим развитием вашего проекта, позволяя вам
					максимизировать бизнес-результаты и увеличить доход без особых усилий.`}
				</h2>
			</div>

			<div className="icons">
				{icons.map((icon, index) => {
					return (
						<motion.div
							key={index}
							className="icon"
							data-index={index}
							style={isMobile ? {} : { rotate, y: positionY }}
						>
							<img src={icon} alt={`Icon ${index}`} />
						</motion.div>
					);
				})}
			</div>
		</div>
	);
};

export default ParallaxComponent;
